import React from "react";
import i18n from "../../locales";
import TextSection from "../../ui-kit/textSection/textSection";
import SanitaizedText from "../../ui-kit/sanitaizedText/sanitaizedText";

const PrivacyPolicyPage = () => {
  return (
    <TextSection>
      <h1>{i18n.t("privacyPolicy")}</h1>
      <SanitaizedText text={i18n.t("privacyPolicyText")} />
    </TextSection>
  );
};

export default PrivacyPolicyPage;
