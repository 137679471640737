// dev back
// export const DOMAIN = "https://nltdevshop.pp.ua/";
// prod back
// export const DOMAIN = "https://apulse-back.pp.ua/";
export const DOMAIN = "https://apulse-back.pp.ua/";
export const CLASSES = {
  menuOpen: "body--open_menu_state",
  megamenuOpen: "body--mega_menu_state",
  bodyScrollHide: "body--scroll_hide_state",
  wrapperBlurred: "body--wrapper_blurred_state",
  blurred: "body--blurred_state",
  paddingLock: "html--lock_padding_state",
};

export const ROUTES = {
  Home: "/",
  NotFound: "*",
  NotFoundLink: "/404",

  PromotionList: "promotions",
  SinglePromotionPage: "promotions/:id",

  ProductList: "products",
  CategoryProducts: "products/:id",

  SearchResultPage: "search-result",
  SearchResult: "search-result/:name",

  Categories: "categories",
  CategoriesItem: "categories/:id",

  SingleProduct: "/product",
  CategoryProduct: "/product/:id",

  NewsPage: "/news",
  SingleNewsPage: "/news/:id",

  WarrantyConditions: "/public-offer-agreement",
  PrivacyPolicy: "/privacy-policy",

  Profile: "/profile",
  Favorites: "/favorites",
  Cart: "/cart",

  Orders: "/orders",
  OrderItem: "/orders/:id",

  Ordering: "/ordering",

  Notifications: "/notifications",
  NotifyProducts: "/notify-products",

  ContactsPage: "/contacts",

  SuccessConfirmEmail: "/success-email-confirm",
  FailureConfirmEmail: "/fail-email-confirm",

  TelegramBot: "/auth/telegram-radio-link",
};

export const LOCAL_STORAGE = {
  theme: "theme",
  darkTheme: "dark-theme",
  lightTheme: "light-theme",

  language: "lang",

  user: "user",
  token: "token",
  confirmMod: "confirmMod",
  loggedIn: "loggedIn",

  cart: "cart",
};

export const DEFAULT_LANGUAGE = "uk";

export const SLIDES_PER_PAGE = 6;
export const PRODUCTS_PER_PAGE = 20;
export const REVIEWS_PER_PAGE = 10;

export const VALIDATED_VALUES = {
  language:
    (navigator.language && navigator.language.substring(0, 2).toLowerCase()) ||
    DEFAULT_LANGUAGE,
};

export const PROMISE_STATES = {
  pending: "pending",
  fulfilled: "fulfilled",
  rejected: "rejected",
  idle: "idle",
};

export const REGULARS = {
  isNumbersOnly: /^\d*$/,
};

export const ROLES = {
  superAdmin: "SuperAdmin",
  manager: "Manager",
  user: "User",
};

export const PRODUCT_AVAILABLE = {
  available: "Available",
  toOrder: "ToOrder",
  unavailable: "Unavailable",
};
